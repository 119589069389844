<!-- eslint-disable -->
<template>
  <v-app>
    <c-v-app-bar v-model="expandOnHover" />

    <c-v-drawer :expand-on-hover.sync="expandOnHover" />

    <c-v-view />

    <!-- <c-v-settings v-model="expandOnHover" /> -->
  </v-app>
</template>

/* eslint-disable */

<script>
  export default {
    name: 'DashboardLayout',

    components: {
      CVAppBar: () => import('../../components/coreview/CVAppBar'),
      CVDrawer: () => import('../../components/coreview/CVDrawer'),
      CVView: () => import('../../components/coreview/CVView'),
      // CVSettings: () => import('../../components/coreview/CVSettings'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
